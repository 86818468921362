import React, { FC, lazy, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { TError } from 'types/errors';
import { useHistory } from 'react-router-dom';
import { EYesNo } from 'config/general';
import { QRRoutes } from '../../routes';
import Loader from '../../components/Loader';
import CapturePhoto from '../../components/CapturePhoto';
import { EOnOff } from '../../types';
import { requestStartVideoCall } from '../../services';
import styles from './styles.module.scss';
import { QRContext } from '../../context';
import Header from '../../components/Header';
import CameraIcon from '../../icons/Camera';
import ListIcon from '../../icons/List';
import PinIcon from '../../icons/Pin';
import ScanPackageIcon from '../../icons/ScanPackage';
import FallBakCall from '../../components/FallbakCall';
import { EndCallTypes, FALLBACK_CALL_TIMEOUT } from '../../config';
import { analytics, analyticsEvents, analyticsEventTypes, trackApiError } from '../../analytics';

const JoinCallRoom = lazy(() => import('../../components/JoinCallRoom'));

const AccessOptions: FC = () => {
    const { qrDetails, location, setCallDetails } = useContext(QRContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCapturePhoto, setShowCapturePhoto] = useState<boolean>(false);
    const [openCallModal, setOpenCallModal] = useState<boolean>(false);
    const [openFallbackCallModal, setOpenFallbackCallModal] = useState<boolean>(false);
    const [capturedPhoto, setCapturedPhoto] = useState<File | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fallBackCallTimeoutRef = useRef<any>();
    const hadFallBackCallRef = useRef<boolean>(false);

    const { push } = useHistory();

    useEffect(() => {
        analytics?.page(analyticsEvents.options, {
            event_type: analyticsEventTypes.pageLoad,
        });
    }, []);

    useEffect(() => {
        if (qrDetails) {
            analytics?.track(analyticsEvents.options, {
                event_type: analyticsEventTypes.optionsInfo,
                enable_package_scan: qrDetails.enable_package_scan,
                enable_tenant_intercom: qrDetails.enable_tenant_intercom,
                enable_visitor_enter_pin: qrDetails.enable_visitor_enter_pin,
                enable_visitor_front_desk_call: qrDetails.enable_visitor_front_desk_call,
                front_desk_text: qrDetails.front_desk_text,
                tenant_intercom_text: qrDetails.tenant_intercom_text,
                visitor_button_label_text: qrDetails.visitor_button_label_text,
            });
        }
    }, [qrDetails]);

    const reqVideoCall = useCallback(
        async ({ photo, isFallbackCall }: { photo: File; isFallbackCall: boolean }) => {
            setShowCapturePhoto(false);
            if (!location) {
                toast.error('Location permissions not found, please enable location permissions and try again.');
                return;
            }
            setIsLoading(true);

            if (!photo) {
                toast.error('Failed to capture photo');
                return;
            }

            try {
                analytics?.track(analyticsEvents.options, {
                    event_type: analyticsEventTypes.callInitialized,
                });
                const data = await requestStartVideoCall({
                    call_type: 'FRONT_DESK',
                    longitude: location.longitude,
                    latitude: location.latitude,
                    files: [photo],
                    fallback_call: isFallbackCall ? EYesNo.yes : EYesNo.no,
                });
                if (data.msg) {
                    toast.error(data.msg);
                } else {
                    setCallDetails(data);
                    setOpenCallModal(true);
                    setOpenFallbackCallModal(false);
                    if (data.is_fallback_front_desk_available && !hadFallBackCallRef.current) {
                        fallBackCallTimeoutRef.current = setTimeout(() => {
                            setOpenFallbackCallModal(true);
                            setOpenCallModal(false);
                        }, FALLBACK_CALL_TIMEOUT);
                    }
                }
            } catch (err) {
                const error = err as TError;
                push(QRRoutes.permissions);
                toast.error(error?.message);
                trackApiError(error, analyticsEvents.options);
            } finally {
                setIsLoading(false);
            }
        },
        [location, setCallDetails, push],
    );

    const toggleCapturePhoto = useCallback(() => {
        setShowCapturePhoto(prev => !prev);
    }, []);

    const onEndCall = useCallback(
        (type: EndCallTypes) => {
            setOpenCallModal(false);
            clearTimeout(fallBackCallTimeoutRef.current);
            analytics?.track(analyticsEvents.options, {
                event_type: type,
            });
        },
        [setOpenCallModal],
    );

    const onAnswerCall = useCallback(() => {
        analytics?.track(analyticsEvents.options, {
            event_type: analyticsEventTypes.callAnswered,
        });
        clearTimeout(fallBackCallTimeoutRef.current);
    }, []);

    const toggleFallbackCallModal = useCallback(() => {
        setOpenFallbackCallModal(prev => !prev);
    }, []);

    const onPhotoCapture = useCallback(
        (file: File | null) => {
            setCapturedPhoto(file);
            if (file) {
                analytics?.track(analyticsEvents.options, {
                    event_type: analyticsEventTypes.userPhotoCaptured,
                });
                reqVideoCall({ photo: file, isFallbackCall: false });
            } else {
                toast.error('Failed to capture photo');
                analytics?.track(analyticsEvents.options, {
                    event_type: analyticsEventTypes.failedCaptureUserPhoto,
                });
                push(QRRoutes.permissions);
            }
        },
        [push, reqVideoCall],
    );

    const handleFallBackCallPress = useCallback(() => {
        hadFallBackCallRef.current = true;
        if (capturedPhoto) {
            analytics?.track(analyticsEvents.options, {
                event_type: analyticsEventTypes.secondaryFrontDescCallInitialized,
            });
            reqVideoCall({ photo: capturedPhoto, isFallbackCall: true });
        }
    }, [capturedPhoto, reqVideoCall]);

    const onCallFrontDeskPress = useCallback(() => {
        analytics?.track(analyticsEvents.options, {
            event_type: analyticsEventTypes.callFrontDeskPressed,
        });
        toggleCapturePhoto();
    }, [toggleCapturePhoto]);

    const onCapturePhotoLoad = useCallback(() => {
        analytics?.track(analyticsEvents.options, {
            event_type: analyticsEventTypes.captureSelfieScreenLoaded,
        });
    }, []);

    const onDoorUnlock = useCallback(() => {
        analytics?.track(analyticsEvents.options, {
            event_type: analyticsEventTypes.doorUnlocked,
        });
    }, []);

    const onDoorUnlockFailed = useCallback(() => {
        analytics?.track(analyticsEvents.options, {
            event_type: analyticsEventTypes.doorUnlockFailed,
        });
    }, []);

    return (
        <section className={styles.options}>
            <div>
                <Header />
                <p className={styles.titleTxt}>Welcome to</p>
                <h2 className={styles.title}>{qrDetails?.site_name}</h2>
                <h5 className={styles.address}>{qrDetails?.qr_code_address || qrDetails?.address}</h5>
            </div>
            <div className={styles.opts}>
                {qrDetails?.enable_visitor_front_desk_call === EOnOff.on && (
                    <div className={styles.btnBox}>
                        <button type='button' onClick={onCallFrontDeskPress} disabled={isLoading}>
                            {qrDetails.front_desk_text}
                            {isLoading ? <Loader color='#000' size={18} /> : <CameraIcon />}
                        </button>
                    </div>
                )}
                {qrDetails?.enable_tenant_intercom === EOnOff.on && (
                    <div className={styles.btnBox}>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={() => {
                                push(QRRoutes.callTenant);
                                analytics?.track(analyticsEvents.options, {
                                    event_type: 'Call Tenant Pressed',
                                    action: '',
                                });
                            }}
                        >
                            {qrDetails.tenant_intercom_text}
                            <ListIcon />
                        </button>
                    </div>
                )}
                {qrDetails?.enable_visitor_enter_pin === EOnOff.on && (
                    <div className={styles.btnBox}>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={() => {
                                analytics?.track(analyticsEvents.options, {
                                    event_type: 'Pin Access Pressed',
                                    action: '',
                                });
                                push(QRRoutes.pinCode);
                            }}
                        >
                            PIN access
                            <PinIcon />
                        </button>
                    </div>
                )}
                {qrDetails?.enable_package_scan === EOnOff.on && (
                    <div className={styles.btnBox}>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={() => {
                                analytics?.track(analyticsEvents.options, {
                                    event_type: 'Scan Package Pressed',
                                    action: '',
                                });
                                push(QRRoutes.packageScan);
                            }}
                        >
                            SCAN PACKAGE
                            <ScanPackageIcon />
                        </button>
                    </div>
                )}
            </div>

            {showCapturePhoto && <CapturePhoto onPhotoCapture={onPhotoCapture} onLoad={onCapturePhotoLoad} />}
            {openCallModal && (
                <JoinCallRoom
                    roomLabel='FRONT DESK'
                    isFrontDesc
                    onEndCall={onEndCall}
                    onAnswerCall={onAnswerCall}
                    onDoorUnlock={onDoorUnlock}
                    onDoorUnlockFailed={onDoorUnlockFailed}
                />
            )}
            {openFallbackCallModal && (
                <FallBakCall isLoading={isLoading} onCallPress={handleFallBackCallPress} onCancelPress={toggleFallbackCallModal} />
            )}
        </section>
    );
};

export default AccessOptions;
