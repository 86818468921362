import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ICustomMap } from 'types/ICustomMap';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { DOOR_UNLOCK_TIMEOUT } from '../../config';
import { QRRoutes } from '../../routes';
import { requestPinUnlock } from '../../services';
import { QRContext } from '../../context';
import PinIcon from '../../icons/Pin';
import styles from './styles.module.scss';
import CapturePhoto from '../../components/CapturePhoto';
import DoorUnlocked from '../../components/DoorUnlocked';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import Loader from '../../components/Loader';
import { analytics, analyticsEvents, analyticsEventTypes, trackApiError } from '../../analytics';

const EnterPin = () => {
    const { qrDetails, location } = useContext(QRContext);
    const [pin, setPin] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showCapturePhoto, setShowCapturePhoto] = useState<boolean>(false);
    const [isUnlocked, setIsUnlocked] = useState<boolean>(false);

    const { goBack, push } = useHistory();

    useEffect(() => {
        analytics?.page(analyticsEvents.pinAccess, {
            event_type: analyticsEventTypes.pageLoad,
        });
    }, []);

    const unlock = useCallback(
        async (photo: File | null) => {
            setShowCapturePhoto(false);
            if (!pin) {
                toast.error('Please enter PIN');
                return;
            }

            if (!photo) {
                analytics?.track(analyticsEvents.pinAccess, {
                    event_type: analyticsEventTypes.failedCaptureUserPhoto,
                });
                toast.error('Failed to capture photo');
                return;
            }

            analytics?.track(analyticsEvents.pinAccess, {
                event_type: analyticsEventTypes.userPhotoCaptured,
            });

            try {
                setIsLoading(true);
                await requestPinUnlock({
                    visitor_pin: pin,
                    files: [photo],
                    latitude: location?.latitude,
                    longitude: location?.longitude,
                });
                setIsUnlocked(true);
                analytics?.track(analyticsEvents.pinAccess, {
                    event_type: analyticsEventTypes.doorUnlocked,
                });
                setTimeout(() => {
                    // setStep(EQRSteps.accessOptions);
                    push(QRRoutes.accessOptions);
                }, DOOR_UNLOCK_TIMEOUT);
            } catch (err) {
                const error = err as TError;
                toast.error(error?.message);
                analytics?.track(analyticsEvents.pinAccess, {
                    event_type: analyticsEventTypes.pinError,
                });
                trackApiError(error, analyticsEvents.pinAccess);
            } finally {
                setIsLoading(false);
            }
        },
        [location, pin, push],
    );

    const toggleCapturePhoto = useCallback(() => {
        setShowCapturePhoto(prev => !prev);
    }, []);

    const handleKeyDown = useCallback(
        async (event: ICustomMap) => {
            if (event.key === 'Enter') {
                toggleCapturePhoto();
            }
        },
        [toggleCapturePhoto],
    );

    const handleSubmitPin = useCallback(() => {
        if (!pin) {
            toast.error('Please enter PIN');
            return;
        }

        analytics?.track(analyticsEvents.pinAccess, {
            event_type: analyticsEventTypes.submitPinButtonPressed,
        });

        toggleCapturePhoto();
    }, [pin, toggleCapturePhoto]);

    const onCapturePhotoLoad = useCallback(() => {
        analytics?.track(analyticsEvents.pinAccess, {
            event_type: analyticsEventTypes.captureSelfieScreenLoaded,
        });
    }, []);

    return (
        <div className={styles.root}>
            {isUnlocked && <DoorUnlocked address={qrDetails?.qr_code_address || ''} title='PIN Unlock' />}
            <header>
                <button disabled={isLoading} type='button' onClick={goBack}>
                    <ArrowLeftIcon />
                </button>

                <p>ENTER PIN</p>
                <div>
                    <PinIcon />
                </div>
            </header>
            <div className={styles.content}>
                <div className={styles.field}>
                    <input
                        disabled={isLoading}
                        value={pin}
                        type='tel'
                        placeholder='*****'
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        onChange={e => setPin(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <button className={styles.btn} type='button' disabled={isLoading} onClick={handleSubmitPin}>
                    Submit PIN
                    {isLoading && <Loader className={styles.loader} color='#000' size={18} />}
                </button>
            </div>
            {showCapturePhoto && <CapturePhoto onPhotoCapture={unlock} onLoad={onCapturePhotoLoad} />}
        </div>
    );
};

export default EnterPin;
